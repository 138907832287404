import * as Yup from "yup";

declare module "yup" {
  export interface StringSchema {
    validateEmailRFC5322(message?: string): StringSchema;
  }
}

Yup.addMethod<Yup.StringSchema>(
  Yup.string,
  "validateEmailRFC5322",
  function (message = "Invalid e-mail address") {
    return this.test(
      "is-email-compliant-with-RFC5322",
      message,
      function validateEmailRFC5322(email?: string) {
        const isContainAt = email?.includes("@");

        if (!email || !isContainAt) {
          return false;
        }

        const [localPart, domain] = email.split("@");
        const firstLevel = domain.split(".")[0];

        const isLocalPartLengthValid =
          localPart.length >= MINIMAL_LENGTH &&
          localPart.length <= LOCAL_PART_LENGTH;

        const areLocalPartCharactersValid =
          allowedLocalPartRegExp.test(localPart);

        const isDomainLengthValid =
          firstLevel.length >= MINIMAL_LENGTH &&
          firstLevel.length <= FIRST_LEVEL_DOMAIN_LENGTH &&
          domain.length >= MINIMAL_LENGTH &&
          domain.length <= DOMAIN_LENGTH;

        const areDomainCharactersValid =
          allowedDomainCharactersRegExp.test(domain);

        return (
          isLocalPartLengthValid &&
          areLocalPartCharactersValid &&
          isDomainLengthValid &&
          areDomainCharactersValid
        );
      }
    );
  }
);

const MINIMAL_LENGTH = 1;
const LOCAL_PART_LENGTH = 64;
const FIRST_LEVEL_DOMAIN_LENGTH = 63;
const DOMAIN_LENGTH = 255;

/*
  All unicode letters and number with printable characters !#$%&'*+-/=?^_`{|}~;
*/
const allowedLocalPartRegExp = new RegExp(
  "^[\\p{L}\\p{N}!#$%&'*+-/=?^_`{|}~]+$",
  "u"
);

/*
  All unicode letters and number with . and -;
  without double dot (..) - empty second level domain
*/
const allowedDomainCharactersRegExp = new RegExp(
  "^(?!.*\\.{2})[\\p{L}\\p{N}.-]+$",
  "u"
);
