import { styled } from "@mui/material";
import { Card } from "@volt_developers/components";
import { getSpacing } from "@volt_developers/foundations";

import { EnvironmentPill } from "../EnvironmentPill";

import type { FormProps } from "@volt_developers/components";
import type { ReactNode } from "react";

interface EnvironmentBodyProps extends FormProps {
  action: ReactNode;
}
export function EnvironmentBody({
  action,
  header,
  ...props
}: EnvironmentBodyProps) {
  return (
    <Card.Body
      headerTypography={{ variant: "h5" }}
      sxHeader={{ m: 0 }}
      header={
        <HeaderWrapper>
          <Card.Header header={header} sx={{ p: 0, m: 0 }} />
          <EnvironmentPill sx={{ ml: getSpacing(4) }} />
          <ActionWrapper>{action}</ActionWrapper>
        </HeaderWrapper>
      }
      {...props}
    />
  );
}

const HeaderWrapper = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
});

const ActionWrapper = styled("div")({
  marginLeft: "auto",
});
