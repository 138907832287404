import { Skeleton } from "@mui/material";

export function CardHeaderSkeleton() {
  return (
    <Skeleton
      sx={{
        width: SKELETON_WIDTH,
        height: SKELETON_HEIGHT,
      }}
    />
  );
}

const SKELETON_WIDTH = 160;
const SKELETON_HEIGHT = 20;
