import { all, fork, take } from "redux-saga/effects";

import { rootSaga as authSaga } from "modules/auth/store/auth.saga";
import { rootSaga as resetPasswordSaga } from "modules/auth/store/resetPassword.saga";
import { rootSaga as accountsSaga } from "modules/bank-accounts/store/accounts.saga";
import { rootSaga as checkoutBrandingSaga } from "modules/checkout/store/saga";
import { blocklistSaga } from "modules/circuit-breaker/store/blocklist/blocklist.saga";
import { riskOverviewSaga } from "modules/circuit-breaker/store/riskOverview/riskOverview.saga";
import { riskRulesSaga } from "modules/circuit-breaker/store/riskRules/riskRules.saga";
import { rootSaga as userManagementSaga } from "modules/user-management/store/users.saga";
import { rootSaga as termsAndConditionsSaga } from "modules/user-settings/store/termsAndConditions.saga";
import { init } from "store/appSlice";

import { rootSaga as appSaga } from "./app";
import { rootSaga as valuesSaga } from "./values";

export default function* root() {
  yield take(init().type);
  // Wait for rehydrate to get applied to prevent sagas from running with empty store
  yield all([
    fork(authSaga),
    fork(accountsSaga),
    fork(userManagementSaga),
    fork(resetPasswordSaga),
    fork(checkoutBrandingSaga),
    fork(valuesSaga),
    fork(appSaga),
    fork(blocklistSaga),
    fork(riskRulesSaga),
    fork(riskOverviewSaga),
    fork(termsAndConditionsSaga),
  ]);
}
