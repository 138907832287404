/*eslint perfectionist/sort-objects: "error"*/

export const enGb = {
  accountName: "Account Name",
  accountNumber: "Account number",
  add: "Add",
  amount: "Amount",
  amountRefundedAlready: "Amount refunded already",
  apiOnly: "API only",
  application: "Application",
  backToAllPayments: "Back to all payments",
  bank: "Bank",
  bankResponses: "Bank responses",
  beneficiary: "Beneficiary",
  branch: "Branch",
  bsb: "BSB",
  cancel: "Cancel",
  cancelUrl: "Cancel URL",
  cannotDownloadReportNow:
    "Cannot download report now. Please, try again later",
  ccy: "CCY",
  checkout: "(Checkout)",
  clear: "Clear",
  clientError: "Client Error",
  cloudWatch: "CloudWatch",
  completed: "Completed",
  confirmationCode: "Confirmation code",
  country: "Country",
  currency: "Currency",
  customer: "Customer",
  customerMustHavePayByLinkProductFlagEnabled:
    "Customer must have Pay by Link product flag enabled to be selected",
  customerName: "Customer name",
  date: "Date (UTC)",
  delivered: "Delivered",
  details: "Details",
  deviceFingerprint: "Device fingerprint",
  download: "Download",
  downloadReport: "Download report",
  email: "Email",
  expiryDate: "Expiry Date",
  expiryDateTime: "Expiry Date & Time",
  expiryDateUTC: "Expiry Date (UTC)",
  failed: "Failed",
  failedToLoadCustomerDetailsPleaseTryAgainLater:
    "Failed to load customer details. Please, try again later.",
  failureUrl: "Failure URL",
  firstName: "First name",
  fromToExchangeRate: "{{from}}/{{to}} exchange rate",
  generated: "Generated",
  generateLink: "Generate Link",
  generateReport: "Generate a report",
  generating: "Generating",
  generatingWithDots: "Generating...",
  givenPaymentCouldNotBeFound:
    "Given payment could not be found. Make sure you are on correct mode (production/sandbox).",
  grafana: "Grafana",
  group: "Group",
  helpYourCustomerPayFaster:
    "Help your customer pay faster by pre-selecting country. This will allow us to display relevant banks available in this country on Volt Checkout.",
  httpCode: "HTTP Code",
  iban: "IBAN",
  id: "ID",
  ignore: "Ignore",
  inProgress: "In progress...",
  ip: "IP",
  isoCode: "ISO code",
  kibana: "Kibana",
  lastInstitutionError: "Last institution error",
  lastName: "Last name",
  lastTen: "(last 10)",
  leadingToFailure: "Leading to failure",
  legalName: "Legal name",
  licence: "License",
  linkCouldNotBeGenerated:
    "Link could not be generated. Check the form for encountered problems.",
  linkWillExpire: "Link will expire at 23:59 UTC that day",
  listOfReportsIsNotAvailableNow:
    "List of reports is not available now. Please, try again later.",
  merchantInternalReference: "Merchant internal reference",
  name: "Name",
  networkError: "Network Error",
  newPayByLink: "New Pay by Link",
  noBankChosen: "No bank chosen",
  noBankChosenTooltip:
    "Consumers who haven't chosen a bank or shown intent to transact are excluded by default.",
  noPayments: "No payments",
  noRecords: "No records",
  noResults: "No Results",
  notAvailable: "Not Available",
  notificationResponse: "Notification Response",
  notifications: "Notifications",
  notificationsX: "Notifications ({{count}})",
  notReceived: "Not received",
  notSelected: "Not selected",
  notSent: "Not Sent",
  organisationAsAPayer: "Organisation as a payer",
  organizationName: "Organization name",
  payByLink: "Pay by Link",
  payByLinkDetails: "Pay by Link Details",
  payByLinkHasBeenSuccessFullyGenerated:
    "Pay by Link has been successfully generated.",
  payer: "Payer",
  payerReference: "Payer Reference",
  payId: "PayID",
  payment: "Payment",
  paymentAuthFailed: "Payment auth failed",
  paymentDeclined: "Payment declined",
  paymentDescription: "Payment description",
  paymentDescriptionTooltip:
    "Additional data about the payment. Value that is sent to PayTo, presented in shoppers bank as payment and payment agreement description. ASCII-printable characters only.",
  paymentDetails: "Payment details",
  paymentExpired: "Payment expired",
  paymentFailed: "Payment failed",
  paymentHasBeenFullyRefunded:
    "Refund time period for this payment has been exceeded.",
  paymentNotFound: "Payment not found",
  paymentNotReceived: "Payment not received",
  paymentNotRefunded: "Payment not refunded",
  paymentPending: "Payment pending",
  paymentProvider: "(Payment Provider)",
  paymentReceived: "Payment received",
  paymentRefunded: "Payment refunded",
  paymentRegistered: "Payment registered",
  payments: "Payments",
  paymentSuccess: "Payment success",
  paymentUnknown: "Payment unknown",
  pending: "Pending",
  pendingUrl: "Pending URL",
  pix: "PIX",
  pleaseSelectApplication: "Please select application",
  provider: "Provider",
  providerDetailedStatus: "Provider detailed status",
  received: "Received",
  redirect: "Redirect",
  reference: "Reference",
  refund: "Refund",
  refundAmountRemaining: "Refund amount remaining",
  refundConfirmed: "Refund confirmed",
  refundDaysRemaining: "Refund days remaining",
  refundFailed: "Refund failed",
  refundId: "Refund ID",
  refunds: "Refunds",
  refundsDetails: "Refunds details",
  refundsRequested: "Refunds requested",
  refundsWereNotActivatedForThisCustomer:
    "Refunds were not activated for this customer",
  refundTimePeriodForThisPayment:
    "Refund time period for this payment has been exceeded",
  refundTransaction: "Refund transaction",
  reportCouldNotBeRemovedPleaseTryAgainLater:
    "Report could not be removed. Please, try again later.",
  reportFailedToGenerate: "Report failed to generate",
  reportIsBeingGenerated: "Report is being generated...",
  reports: "Reports",
  reportsCantExceedLastThreeMonths:
    "Reports can’t exceed last 3 months, please refine your filter.",
  reportsHistory: "Reports history",
  requestedBy: "Requested by",
  requestedViaParentUser: "requested via parent’s user",
  response: "Response",
  retry: "Retry",
  routingNumber: "Routing number",
  search: "Search",
  searchByPaymentId: "Search by Payment ID",
  searchCountCharactersMin: "Search ({{count}} characters min.)",
  searchCountry: "Search country",
  searchCurrency: "Search currency",
  seeMoreRefunds: "See more refunds",
  seemsLikeYouDoNotHavePermissionToViewPayersBankDetails:
    "Seems like you don't have permission to view payer's bank details. Contact your administrator if you think there is an error.",
  selectAnApplication:
    "Select an application, so that we can send the shopper to the correct page, based on which of these states the payment results in.",
  serverError: "Server Error",
  setFiltersOnPayments:
    "Set filters on the payments so their total is lower or equal {{limit}}",
  settledAt: "Settled at",
  settlement: "Settlement",
  settlementReference: "Settlement reference",
  showLess: "show less",
  showMore: "show more",
  showPaymentsWithNoBankSelection: "Show payments with no bank selection",
  sorrySomethingWentWrong:
    "Sorry, something went wrong. Please try again later.",
  sortCode: "Sort Code",
  status: "Status",
  statusAbandonedByUser: "Abandoned by user",
  statusAccountSelection: "Account selection",
  statusAdditionalAuthorizationRequired: "Additional authorization required",
  statusApprovedByRisk: "Approved by risk",
  statusAuthorizedByUser: "Authorized by user",
  statusAwaitingCheckoutAuthorization: "Awaiting checkout authorisation",
  statusBankRedirect: "Bank redirect",
  statusCancelledByUser: "Cancelled by user",
  statusCompleted: "Completed",
  statusDelayedAtBank: "Delayed at bank",
  statusErrorAtBank: "Error at bank",
  statusFailed: "Failed",
  statusNewPayment: "New payment",
  statusNotReceived: "Not received",
  statusReceived: "Received",
  statusRefusedByBank: "Refused by bank",
  statusRefusedByRisk: "Refused by risk",
  statusSettled: "Settled",
  statusUnknown: "Unknown",
  submitTheDetailsOfThePayment:
    "Submit the details of the payment you'd like to initiate and we'll validate that this is a payment we can support.",
  success: "Success",
  successUrl: "Success URL",
  swiftCode: "Swift code (BIC)",
  test: "Test",
  thereAreNoNotifications:
    "There are no notifications for this transaction. If this doesn't seem right, please contact your implementation team.",
  thisValueShouldBeGreaterThan:
    "This value should be greater than or equal to the minimum one",
  timeoutError: "Timeout Error",
  tooManyRetries: "Too many retries",
  totalCharacters: "{{count}}/{{maxAmount}} character remaining",
  totalCharacters_plural: "{{count}}/{{maxAmount}} characters remaining",
  transaction: "Transaction",
  transactionList: "Transaction list",
  transactions: "Transactions",
  transferStatus: "Transfer status",
  transferType: "Transfer type",
  tryAgain: "Try again",
  uniqueReference: "Unique reference",
  view: "View",
  viewReportsHistory: "View reports history",
  viewReportsHistoryLoading: "Reports history loading...",
  volt: "Volt",
  voltConnect: "(Volt Connect)",
  voltCustomerId: "Volt customer ID",
  voltPaymentId: "Volt payment ID",
  voltSettlementId: "Volt settlement ID",
  weAreGatheringMoreInformationAboutThePayment:
    "We are gathering more information about the payment",
  weAreWaitingForYourFirstPayment: "We're waiting for your first payment",
  youCannotFilterByAmountIfMoreCurrenciesSelected:
    "You cannot filter by amount if more than one currency is selected",
  youCannotRequestMoreThan5Reports:
    "You cannot request more than 5 reports at the same time",
  youCurrentlyHaveSelectedCustomerApplied:
    "You currently have a selected customer applied that is limiting the customers you see. To view all customers, please clear the filter.",
  youHaveNoReportsHistory: "You have no reports history",
  youMustHavePermissionForViewingApplications:
    "You must have permission for viewing applications in order to generate a Pay by Link payment.",
  yourReportIsCurrentlyBeingGenerated:
    "Your report is currently being generated and we will let you know when it is available. You can also download it later in the Reports section.",
  youSearchWithoutFiltersAndDateRange:
    "You search without filters and date range",
};
