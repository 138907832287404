import { subject } from "@casl/ability";
import axios from "axios";
import isNil from "lodash/isNil";

import { combineUrls } from "api/helpers/combineUrls";
import { getErrorDetails } from "api/helpers/getErrorDetails";
import { addPaginationAndSort, ORDER_DESC } from "api/helpers/pagination";
import { paramsSerializer } from "api/helpers/paramsSerializer";
import { resolveApiUrl } from "api/helpers/resolveApiUrl";
import { portalApi, TAGS } from "api/rtkApi";
import { DEFAULT_PAGINATION } from "constants/defaultPagination";
import { SUBJECT_ACCOUNT } from "models/role/subject";
import { preparePaginationParams } from "utils/preparePaginationParams";

import type { AxiosRequestConfig } from "axios";

import type { CountryCode } from "models/CountryCode";
import type { CurrencyCode } from "models/CurrencyCode";
import type { RTKMeta } from "models/RTKMeta";
import type { UUID } from "utils";
import type { ObjectWithId } from "utils/ObjectWithId";
import type { PaginationParams } from "utils/preparePaginationParams";

import type { OrderableColumns } from "../constants/Account";
import type { Account } from "../models/Account";
import type { FetchAccountPayload } from "../store";

type GetAccountRequest = ObjectWithId;

type GetAccountsRequest = {
  customerHierarchyId?: UUID;
  customer?: UUID;
} & PaginationParams<OrderableColumns>;

type GetAccountsResponse = { accounts: Account[]; totalItems: number };

type PutAccountRequest = ObjectWithId & {
  account: Partial<Account>;
  options?: Record<string, unknown>;
};

export async function getAccounts(
  params: FetchAccountPayload,
  options: AxiosRequestConfig
): Promise<{
  list: Account[];
  page: number;
  totalItems: number;
}> {
  const { customerId, environment } = params;
  const requestParams: Record<string, any> = addPaginationAndSort<Account>({
    orderBy: "createdAt",
    order: ORDER_DESC,
    page: 0,
    limit: DEFAULT_PAGINATION.limit,
    ...params,
  });

  if (!isNil(customerId)) {
    requestParams["customer"] = customerId;
  }

  try {
    const endpointPath = "accounts";

    const endpointUrl = environment
      ? combineUrls(resolveApiUrl(environment), endpointPath)
      : endpointPath;

    const response = await axios({
      ...options,
      method: "GET",
      url: endpointUrl,
      params: requestParams,
      paramsSerializer,
    });
    const { data = [] } = response;

    return {
      list: data,
      totalItems: data.length,
      page: 0,
    };
  } catch (error) {
    throw getErrorDetails(error);
  }
}

export async function getAccount(id: UUID, options: any): Promise<Account> {
  try {
    const response = await axios({
      ...options,
      method: "GET",
      url: `/accounts/${id}`,
    });
    const { data = {} } = response;

    return data;
  } catch (error) {
    throw getErrorDetails(error);
  }
}

export async function patchAccount(
  id: string,
  {
    nickname,
    active,
    bankName,
  }: Partial<Pick<Account, "active" | "bankName" | "nickname">>,
  options: any
): Promise<Account> {
  try {
    const response = await axios({
      ...options,
      method: "PUT",
      url: `/accounts/${id}`,
      data: {
        ...(nickname && { nickname }),
        ...(active && { active }),
        ...(bankName && { bankName }),
      },
    });
    const { data = {} } = response;

    return await getAccount(data.id, options);
  } catch (error) {
    throw getErrorDetails(error);
  }
}

export type PostAccountMutation = Omit<
  Account,
  "active" | "country" | "currency" | "id"
> & {
  country: CountryCode;
  currency: CurrencyCode;
};
type PostAccountResponse = ObjectWithId;

const extendedApi = portalApi.injectEndpoints({
  endpoints: (build) => ({
    getAccounts: build.query<GetAccountsResponse, GetAccountsRequest>({
      query: ({ customerHierarchyId, customer, ...rest }) => ({
        url: "accounts",
        params: {
          customerHierarchyId,
          customer,
          ...preparePaginationParams(rest),
        },
      }),
      providesTags: [TAGS.ACCOUNTS],
      transformResponse: (accounts: Account[], meta: RTKMeta) => ({
        accounts,
        totalItems: Number(meta.response.headers.get("total-items")),
      }),
    }),
    getAccount: build.query<Account, GetAccountRequest>({
      query: ({ id }) => `accounts/${id}`,
      transformResponse: (account: Account) =>
        subject(SUBJECT_ACCOUNT, account),
      providesTags: (_, __, { id }) => [{ type: TAGS.ACCOUNTS, id }],
    }),
    createAccount: build.mutation<PostAccountResponse, PostAccountMutation>({
      query: (data) => ({
        url: "/accounts",
        method: "POST",
        body: data,
      }),
      invalidatesTags: [TAGS.ACCOUNTS],
    }),
    updateAccount: build.mutation<UUID, PutAccountRequest>({
      query: ({ id, account }) => ({
        url: `/accounts/${id}`,
        method: "PUT",
        body: account,
      }),
      async onQueryStarted({ id, account }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          extendedApi.util.updateQueryData(
            "getAccount",
            { id },
            (cachedAccount: Account) => {
              Object.entries(account).reduce((accumulator, [key, value]) => {
                (accumulator as any)[key] = value;

                return accumulator;
              }, cachedAccount);
            }
          )
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: [TAGS.ACCOUNTS],
    }),
    enableSepa: build.mutation<UUID, GetAccountRequest>({
      query: ({ id }) => ({
        url: `/accounts/${id}/enable-sepa-instant`,
        method: "PATCH",
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          extendedApi.util.updateQueryData(
            "getAccount",
            { id },
            (cachedAccount: Account) => {
              cachedAccount.sepaInstantEnabled = true;
            }
          )
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: [TAGS.ACCOUNTS],
    }),
    disableSepa: build.mutation<UUID, GetAccountRequest>({
      query: ({ id }) => ({
        url: `/accounts/${id}/disable-sepa-instant`,
        method: "PATCH",
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          extendedApi.util.updateQueryData(
            "getAccount",
            { id },
            (cachedAccount: Account) => {
              cachedAccount.sepaInstantEnabled = false;
            }
          )
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: [TAGS.ACCOUNTS],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateAccountMutation,
  useGetAccountsQuery,
  useGetAccountQuery,
  useLazyGetAccountQuery,
  useUpdateAccountMutation,
  useEnableSepaMutation,
  useDisableSepaMutation,
} = extendedApi;
