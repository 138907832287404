import type { UUID } from "utils";

const BASE_URL = "/accounts";

export const PAGE_URLS = {
  list: BASE_URL,
  connect: `${BASE_URL}/connect`,
  details: `${BASE_URL}/:id`,
  getLinkToAccountDetails: (id: UUID) => `${BASE_URL}/${id}`,
};
