import { QueryStatus } from "@reduxjs/toolkit/query/react";

import { ORDER_ASC } from "api/helpers/pagination";
import { portalApi, TAGS } from "api/rtkApi";
import { ENV_PRODUCTION, ENV_SANDBOX } from "constants/environment";
import { setGlobalFilteredCustomer } from "store/appSlice";
import { transformFormat } from "utils";
import { pessimisticUpdate } from "utils/pessimisticUpdate";
import { preparePaginationParams } from "utils/preparePaginationParams";
import { isUUID } from "utils/uuid";

import { INCORPORATION_DATE_FORMAT } from "../models/Customer";
import { mapHierarchyArrayToObject } from "../utils/mapHierarchyArrayToObject";

import type {
  BaseQueryFn,
  EndpointBuilder,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";

import type { APPLICATION_ENV } from "constants/environment";
import type { CountryCode } from "models/CountryCode";
import type { RTKMeta } from "models/RTKMeta";
import type { RootState } from "store";
import type { ObjectWithId } from "utils/ObjectWithId";
import type { PaginationParams } from "utils/preparePaginationParams";
import type { UUID } from "utils/uuid";

import type { CustomerFormValues } from "../components/customer-form-content/forms/CustomerForm";
import type { OrderableColumns } from "../constants/Customer";
import type { Customer, CustomerNames } from "../models/Customer";
import type { BaseCustomerCredentials } from "../models/CustomerCredentials";
import type { CustomerFiltersType } from "../models/CustomerFilter";
import type { CustomerPaymentPreferences } from "../models/CustomerPaymentPreferences";
import type {
  CustomerHierarchy,
  CustomerHierarchyTree,
} from "../models/Hierarchy";
import type { PaymentProcessing } from "../models/PaymentProcessing";
import type { ProductFlagsType } from "../models/ProductFlags";

const CUSTOMER_NAMES_LIMIT = 1000;
const CUSTOMER_NAMES_PAGE = 1;

type GetCustomerRequest = {
  id: UUID;
  rootId?: UUID | null;
};

type PutUpdateCustomerStatusRequest = ObjectWithId & {
  customer: {
    active: Customer["active"];
    terminateAt?: Customer["terminateAt"];
  };
};

type GetCustomerNamesResponse = {
  customerNames: CustomerNames;
};

type GetCustomerNamesRequest = {
  customerIds?: UUID | UUID[];
};

export interface CustomerCredentialsFromApi extends BaseCustomerCredentials {
  createdAt: string;
}

export type GetCustomerResponse = Customer;

export type GetCustomerHierarchyResponse = CustomerHierarchy;

export type GetCustomerHierarchyObjectResponse = CustomerHierarchyTree;

export type GetCustomersRequest = {
  customerId?: UUID | UUID[];
  filter?: Partial<CustomerFiltersType>;
  search?: string;
};

export type GetCustomersResponse = {
  customers: GetCustomerResponse[];
  totalItems?: number;
};

export interface GetCustomersPaginatedRequest
  extends PaginationParams<"createdAt" | OrderableColumns> {
  customerHierarchyId?: UUID;
  customersIds?: UUID | UUID[];
  filters?: Partial<CustomerFiltersType>;
}

export type GetCustomersPaginatedResponse = {
  customers: Customer[];
  totalItems: number;
};

export type PostGenerateCredentialsRequest = ObjectWithId;

type PostGenerateCredentialsResponse = {
  customer: UUID;
  password: string;
  username: string;
};

export type PostRegenerateCredentialsRequest = PostGenerateCredentialsRequest;
type PostRegenerateCredentialsResponse = {
  password: string;
};

export type PatchCustomerPaymentPreferencesRequest = {
  paymentPreferences: CustomerPaymentPreferences;
} & ObjectWithId;

export type GetCustomerCountriesRequest = ObjectWithId;
export type GetCustomerCountriesResponse = CountryCode[];

export type PatchCustomerBanksByCountryRequest = ObjectWithId & {
  countries: CountryCode[];
};
export type PatchCustomerBanksByCountryResponse = CountryCode[];

export type PatchCustomerProductFlagsResponse = ObjectWithId;
export type PatchCustomerProductFlagsRequest = ObjectWithId & {
  productFlag: {
    [key in ProductFlagsType]?: boolean;
  };
};

export type PatchCustomerCircuitBreakerProductFlagResponse = ObjectWithId;
export type PatchCustomerCircuitBreakerProductFlagRequest = ObjectWithId & {
  circuitBreakerEnabled: boolean;
};
type PostCustomerRequest = Omit<CustomerFormValues, "parentSearch">;
type PostCustomerResponse = ObjectWithId;
type PatchCustomerRequest = {
  customer: Omit<CustomerFormValues, "parentSearch">;
} & ObjectWithId;
type PatchCustomerResponse = ObjectWithId;

type GetCustomerHierarchyRequest = {
  id: UUID;
  depth?: number;
  rootId?: UUID;
  search?: string;
};

export const CUSTOMERS_AUTOCOMPLETE_RESULTS_LIMIT = 100;
const extendedApi = portalApi.injectEndpoints({
  endpoints: (builder) => ({
    getCustomers: builder.query<
      GetCustomersPaginatedResponse,
      GetCustomersPaginatedRequest
    >({
      query: ({ customersIds, customerHierarchyId, filters, ...rest }) => ({
        url: "customers",
        params: {
          id: customersIds,
          ...(customerHierarchyId && { customerHierarchyId }),
          ...preparePaginationParams(rest),
          ...filters,
        },
      }),
      providesTags: [TAGS.CUSTOMERS],
      transformResponse: (customers: Customer[], meta: RTKMeta) => ({
        customers: customers.map(({ incorporationDate, ...customer }) => ({
          ...customer,
          incorporationDate:
            incorporationDate !== null
              ? transformFormat(incorporationDate, INCORPORATION_DATE_FORMAT)
              : incorporationDate,
        })),
        totalItems: Number(meta.response.headers.get("total-items")),
      }),
    }),
    getCustomersForAutocomplete: builder.query<
      GetCustomersResponse,
      GetCustomersRequest
    >({
      query: ({ customerId, search = "", filter = {} }) => ({
        url: "customers",
        method: "GET",
        params: {
          limit: CUSTOMERS_AUTOCOMPLETE_RESULTS_LIMIT,
          "order[name]": ORDER_ASC,
          page: 1,
          id: isUUID(search) ? search : customerId,
          ...(search.length && {
            search: isUUID(search) ? undefined : search,
          }),
          ...filter,
        },
      }),
      transformResponse: (data: GetCustomerResponse[], meta: RTKMeta) => {
        const totalItems = Number(meta.response.headers.get("total-items"));

        return {
          totalItems,
          customers: data,
        };
      },
      providesTags: [{ type: TAGS.CUSTOMER_AUTOCOMPLETE }],
    }),
    getCustomerHierarchy: builder.query<
      GetCustomerHierarchyResponse[],
      GetCustomerHierarchyRequest
    >({
      query: ({ id, ...params }) => ({
        url: `customers/${id}/hierarchy`,
        method: "GET",
        params: {
          ...params,
        },
      }),
      providesTags: (_, __, { id }) => [{ type: TAGS.CUSTOMER_HIERARCHY, id }],
    }),
    getCustomerNames: builder.query<
      GetCustomerNamesResponse,
      GetCustomerNamesRequest
    >({
      query: ({ customerIds }) => ({
        url: "customers",
        method: "GET",
        params: {
          limit: CUSTOMER_NAMES_LIMIT,
          page: CUSTOMER_NAMES_PAGE,
          id: customerIds,
        },
      }),
      transformResponse: (data: GetCustomerResponse[]) => {
        const customerNames = data.reduce<CustomerNames>(
          (names, currentCustomer) => {
            if (currentCustomer?.id && !names[currentCustomer.id]) {
              names[currentCustomer.id] = currentCustomer.name;
            }

            return names;
          },
          {}
        );

        return {
          customerNames,
        };
      },
      providesTags: [TAGS.CUSTOMER_NAMES],
    }),
    getCustomer: builder.query<GetCustomerResponse, GetCustomerRequest>({
      query: ({ id }) => `customers/${id}`,
      providesTags: (_, __, { id }) => [{ type: TAGS.CUSTOMER, id }],
      onQueryStarted: async (entry, { dispatch, queryFulfilled, getState }) => {
        const state = getState() as RootState;

        if (state.app.globalFilteredCustomer?.id === entry.id) {
          return pessimisticUpdate(queryFulfilled)(
            ({ data: { id, name, globalFilterIds } }) => {
              dispatch(
                setGlobalFilteredCustomer({
                  id,
                  name,
                  allIds: [...(globalFilterIds ?? [])],
                })
              );
            }
          );
        }
      },
      transformResponse: ({
        incorporationDate,
        ...customer
      }: GetCustomerResponse) => ({
        ...customer,
        incorporationDate:
          incorporationDate !== null
            ? transformFormat(incorporationDate, INCORPORATION_DATE_FORMAT)
            : incorporationDate,
      }),
    }),
    getCustomerHierarchyTree: builder.query<
      GetCustomerHierarchyObjectResponse,
      GetCustomerRequest
    >({
      query: ({ id, rootId }) =>
        `v2/customers/${id}/hierarchy?${
          Boolean(rootId) ? `rootId=${rootId}` : ""
        }`,
      providesTags: (_, __, { id }) => [
        { type: TAGS.CUSTOMER_HIERARCHY_TREE, id },
      ],
      transformResponse: (data: GetCustomerHierarchyResponse[]) =>
        mapHierarchyArrayToObject(data[0], data),
    }),
    updateCustomerStatus: builder.mutation<
      Customer,
      PutUpdateCustomerStatusRequest
    >({
      query: ({ id, customer }) => ({
        url: `/customers/${id}`,
        // Once customer is deactivated we have to reset the termination date
        body: { ...(!customer.active && { terminateAt: null }), ...customer },
        method: "PUT",
      }),

      async onQueryStarted(
        { id, customer: incomingCustomer },
        { dispatch, queryFulfilled }
      ) {
        try {
          await queryFulfilled;
          dispatch(
            extendedApi.util.updateQueryData(
              "getCustomer",
              { id },
              (customer) => {
                customer.active = incomingCustomer.active;
                customer.terminateAt = incomingCustomer.terminateAt ?? "";
              }
            )
          );

          dispatch(
            extendedApi.util.invalidateTags([
              {
                type: TAGS.CUSTOMERS,
              },
            ])
          );
        } catch {
          dispatch(
            extendedApi.util.invalidateTags([
              {
                type: TAGS.CUSTOMERS,
                id,
              },
            ])
          );
        }
      },
    }),
    getCustomerCountries: builder.query<
      GetCustomerCountriesResponse,
      GetCustomerCountriesRequest
    >({
      query: ({ id }) => `customers/${id}/countries`,
      providesTags: (_, __, { id }) => [{ type: TAGS.CUSTOMER_COUNTRIES, id }],
    }),
    generateProdCredentials: builder.mutation<
      PostGenerateCredentialsResponse,
      PostGenerateCredentialsRequest
    >({
      extraOptions: {
        env: "prod",
      },
      query: ({ id }) => ({
        url: `/customers/${id}/credentials`,
        method: "POST",
      }),
      async onCacheEntryAdded(
        { id },
        { dispatch, cacheDataLoaded, getCacheEntry }
      ) {
        await cacheDataLoaded;
        const { data, status, isError } = getCacheEntry();

        if (status !== QueryStatus.fulfilled || isError || !data) {
          return;
        }

        const customerToUpdate: CustomerCredentialsFromApi = {
          id: data.customer,
          email: data.username,
          environment: "production",
          active: true,
          createdAt: "TBU",
        };

        dispatch(
          extendedApi.util.updateQueryData(
            "getCustomer",
            { id },
            (cachedCustomer: GetCustomerResponse) => {
              Object.assign(cachedCustomer, {
                credentials: [...cachedCustomer.credentials, customerToUpdate],
              });
            }
          )
        );
      },
    }),
    generateSandboxCredentials: builder.mutation<
      PostGenerateCredentialsResponse,
      PostGenerateCredentialsRequest
    >({
      extraOptions: {
        env: "sandbox",
      },
      query: ({ id }) => ({
        url: `/customers/${id}/credentials`,
        method: "POST",
      }),
      async onCacheEntryAdded(
        { id },
        { dispatch, cacheDataLoaded, getCacheEntry }
      ) {
        await cacheDataLoaded;
        const { data, status, isError } = getCacheEntry();

        if (status !== QueryStatus.fulfilled || isError || !data) {
          return;
        }

        const customerToUpdate: CustomerCredentialsFromApi = {
          id: data.customer,
          email: data.username,
          environment: "sandbox",
          active: true,
          createdAt: "TBU",
        };

        dispatch(
          extendedApi.util.updateQueryData(
            "getCustomer",
            { id },
            (cachedCustomer: GetCustomerResponse) => {
              Object.assign(cachedCustomer, {
                credentials: [...cachedCustomer.credentials, customerToUpdate],
              });
            }
          )
        );
      },
    }),
    regenerateProdCredentials: builder.mutation<
      PostRegenerateCredentialsResponse,
      PostRegenerateCredentialsRequest
    >({
      extraOptions: {
        env: "prod",
      },
      query: ({ id }) => ({
        url: `/customers/${id}/credentials/regenerate`,
        method: "POST",
      }),
    }),
    regenerateSandboxCredentials: builder.mutation<
      PostRegenerateCredentialsResponse,
      PostRegenerateCredentialsRequest
    >({
      extraOptions: {
        env: "sandbox",
      },
      query: ({ id }) => ({
        url: `/customers/${id}/credentials/regenerate`,
        method: "POST",
      }),
    }),
    patchCustomerPaymentPreferences: builder.mutation<
      void,
      PatchCustomerPaymentPreferencesRequest
    >({
      query: ({ id, paymentPreferences }) => ({
        url: `/customers/${id}/preferences/payments`,
        method: "PATCH",
        body: paymentPreferences,
      }),
      async onQueryStarted(
        { id, paymentPreferences },
        { dispatch, queryFulfilled }
      ) {
        try {
          await queryFulfilled;
          dispatch(
            extendedApi.util.updateQueryData(
              "getCustomer",
              { id },
              (customer) => {
                customer.paymentPreferences = paymentPreferences;
              }
            )
          );
        } catch {
          dispatch(
            extendedApi.util.invalidateTags([
              {
                type: TAGS.CUSTOMERS,
                id,
              },
            ])
          );
        }
      },
    }),
    patchCustomerBanksByCountry: builder.mutation<
      PatchCustomerBanksByCountryResponse,
      PatchCustomerBanksByCountryRequest
    >({
      query: ({ id, countries }) => ({
        url: `/customers/${id}/countries/enable-banks`,
        method: "PUT",
        body: countries,
      }),
      onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        return pessimisticUpdate(queryFulfilled)(({ data: countries }) => {
          dispatch(
            extendedApi.util.updateQueryData(
              "getCustomerCountries",
              { id },
              () => countries
            )
          );
        });
      },
    }),
    patchSandboxCustomerProductFlags: buildPatchCustomerProductFlagMutation(
      ENV_SANDBOX,
      builder
    ),
    patchProductionCustomerProductFlags: buildPatchCustomerProductFlagMutation(
      ENV_PRODUCTION,
      builder
    ),
    patchCircuitBreakerProductFlag: builder.mutation<
      PatchCustomerCircuitBreakerProductFlagResponse,
      PatchCustomerCircuitBreakerProductFlagRequest
    >({
      query: ({ id, circuitBreakerEnabled }) => ({
        url: `/customers/${id}`,
        method: "PUT",
        body: { circuitBreakerEnabled },
      }),
      async onQueryStarted(
        { id, circuitBreakerEnabled },
        { dispatch, queryFulfilled }
      ) {
        try {
          await queryFulfilled;
          dispatch(
            extendedApi.util.updateQueryData(
              "getCustomer",
              { id },
              (customer) => {
                customer.circuitBreakerEnabled = circuitBreakerEnabled;
              }
            )
          );
        } catch {
          dispatch(
            extendedApi.util.invalidateTags([
              {
                type: TAGS.CUSTOMERS,
                id,
              },
            ])
          );
        }
      },
    }),
    postCustomer: builder.mutation<PostCustomerResponse, PostCustomerRequest>({
      query: (body) => ({
        url: "/customers",
        method: "POST",
        body,
      }),
      invalidatesTags: [TAGS.CUSTOMERS, TAGS.CUSTOMER],
    }),
    putCustomer: builder.mutation<PatchCustomerResponse, PatchCustomerRequest>({
      query: ({ id, customer }) => ({
        url: `/customers/${id}`,
        method: "PUT",
        body: customer,
      }),
      invalidatesTags: () => [
        { type: TAGS.CUSTOMERS },
        { type: TAGS.CUSTOMER },
        { type: TAGS.CUSTOMER_HIERARCHY },
        { type: TAGS.CUSTOMER_HIERARCHY_TREE },
      ],
    }),
    getPaymentProcessing: builder.query<PaymentProcessing, ObjectWithId>({
      query: ({ id }) => ({
        url: `/customers/${id}/payment-processing`,
        method: "GET",
      }),
      providesTags: [TAGS.PAYMENT_PROCESSING],
    }),
    activateVoltLicence: builder.mutation<boolean, GetCustomerRequest>({
      query: ({ id }) => ({
        url: `/customers/${id}/payment-processing/activate-volt-licence`,
        method: "PATCH",
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          extendedApi.util.updateQueryData(
            "getCustomer",
            { id },
            (cachedAccount: Customer) => {
              if (cachedAccount.paymentProcessing) {
                cachedAccount.paymentProcessing.voltLicenceActive = true;
              }
            }
          )
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: [TAGS.CUSTOMER, TAGS.PAYMENT_PROCESSING],
    }),
    deactivateVoltLicence: builder.mutation<UUID, GetCustomerRequest>({
      query: ({ id }) => ({
        url: `/customers/${id}/payment-processing/deactivate-volt-licence`,
        method: "PATCH",
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          extendedApi.util.updateQueryData(
            "getCustomer",
            { id },
            (cachedAccount: Customer) => {
              if (cachedAccount.paymentProcessing) {
                cachedAccount.paymentProcessing.voltLicenceActive = false;
              }
            }
          )
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (_, __, { id }) => [
        { type: TAGS.CUSTOMER },
        { type: TAGS.CUSTOMER_PAYMENTS_PROVIDERS, id },
        { type: TAGS.PAYMENT_PROCESSING },
      ],
    }),
  }),
  overrideExisting: false,
});

type GetMerchantIntegratorIsInheritedFromQueryRequest = {
  rootId: UUID;
} & ObjectWithId;

const extendedApiCombined = extendedApi.injectEndpoints({
  endpoints: (builder) => ({
    getMerchantIntegratorIsInheritedFrom: builder.query<
      GetCustomerResponse,
      GetMerchantIntegratorIsInheritedFromQueryRequest
    >({
      async queryFn({ id, rootId }, _queryApi, _extraOptions) {
        const customerHierarchyList = await _queryApi.dispatch(
          extendedApi.endpoints.getCustomerHierarchy.initiate({
            id,
            rootId,
            depth: 0,
          })
        );

        // result should contain only one row
        if (
          customerHierarchyList.isError ||
          !Array.isArray(customerHierarchyList.data) ||
          customerHierarchyList.data.length < 1
        ) {
          return {
            error: {
              status: "CUSTOM_ERROR",
              error: "invalid hierarchy response",
            },
          };
        }

        const currentHierarchyPath = customerHierarchyList.data[0].path;

        const pathShouldContainMoreThatOneElement =
          currentHierarchyPath[0] !== rootId || currentHierarchyPath.length < 2;

        if (pathShouldContainMoreThatOneElement) {
          return {
            error: {
              status: "CUSTOM_ERROR",
              error: "invalid path response",
            },
          };
        }

        // second element is always root merchant parent
        const isInheritedFromId = currentHierarchyPath[1];

        const customerResult = await _queryApi.dispatch(
          extendedApi.endpoints.getCustomer.initiate({
            id: isInheritedFromId,
          })
        );

        if (customerResult.isError) {
          if ("status" in customerResult.error) {
            return {
              error: customerResult.error satisfies FetchBaseQueryError,
            };
          }

          return {
            error: {
              status: "CUSTOM_ERROR",
              error: customerResult.error.message ?? "",
            },
          };
        }

        if (!customerResult.data) {
          return {
            error: {
              status: "CUSTOM_ERROR",
              error: "invalid customer response",
            },
          };
        }

        return {
          data: customerResult.data,
        };
      },
    }),
  }),
  overrideExisting: false,
});

function buildPatchCustomerProductFlagMutation(
  env: APPLICATION_ENV,
  builder: EndpointBuilder<BaseQueryFn, string, string>
) {
  const fieldName = env === ENV_PRODUCTION ? "production" : "sandbox";

  return builder.mutation<
    PatchCustomerProductFlagsResponse,
    PatchCustomerProductFlagsRequest
  >({
    extraOptions: {
      env,
    },
    query: ({ id, productFlag }) => ({
      url: `/customers/${id}/product-flags`,
      method: "PATCH",
      body: productFlag,
    }),
    async onQueryStarted({ id, productFlag }, { dispatch, queryFulfilled }) {
      try {
        await queryFulfilled;
        dispatch(
          extendedApi.util.updateQueryData(
            "getCustomer",
            { id },
            (customer) => {
              customer.productFlags = {
                ...customer.productFlags,
                [fieldName]: {
                  ...customer.productFlags[fieldName],
                  ...productFlag,
                },
              };
            }
          )
        );
      } catch {
        dispatch(
          extendedApi.util.invalidateTags([
            {
              type: TAGS.CUSTOMERS,
              id,
            },
          ])
        );
      }
    },
  });
}

export const {
  useGetCustomerHierarchyQuery,
  useGenerateProdCredentialsMutation,
  useGenerateSandboxCredentialsMutation,
  useGetCustomerNamesQuery,
  useGetCustomerQuery,
  useLazyGetCustomerQuery,
  useLazyGetCustomerHierarchyQuery,
  useLazyGetMerchantIntegratorIsInheritedFromQuery,
  useGetCustomerHierarchyTreeQuery,
  useGetCustomerCountriesQuery,
  useGetCustomersForAutocompleteQuery,
  useGetCustomersQuery,
  useRegenerateProdCredentialsMutation,
  useRegenerateSandboxCredentialsMutation,
  usePatchCustomerPaymentPreferencesMutation,
  usePatchCustomerBanksByCountryMutation,
  useUpdateCustomerStatusMutation,
  usePatchSandboxCustomerProductFlagsMutation,
  usePatchProductionCustomerProductFlagsMutation,
  usePatchCircuitBreakerProductFlagMutation,
  usePutCustomerMutation,
  usePostCustomerMutation,
  useGetPaymentProcessingQuery,
  useActivateVoltLicenceMutation,
  useDeactivateVoltLicenceMutation,
} = extendedApiCombined;

export { extendedApiCombined as customersApi };
export const { getCustomer } = extendedApiCombined.endpoints;
