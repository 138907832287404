import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import { useCurrentUserAnalyticsProperties } from "modules/auth/hooks/useCurrentUserAnalyticsProperties";

const DEFAULT_PAGE_TITLE = "Volt Fuzebox" as const;

export const useRouterAnalytics = () => {
  const userAnalyticsProperties = useCurrentUserAnalyticsProperties();
  const location = useLocation();
  const [currentPageTitle, setCurrentPageTitle] =
    useState<string>(DEFAULT_PAGE_TITLE);
  const lastReportedPageTitle = useRef("");

  const onHelmetChangeClintState = ({ title }: { title?: string }) => {
    if (title && title !== DEFAULT_PAGE_TITLE) {
      setCurrentPageTitle(title);
    }
  };

  const reportPageView = useCallback(() => {
    lastReportedPageTitle.current = currentPageTitle;

    // For the transition period we need both GA and GTM setup.
    // When GTM will be running on production, then GA branch will be removed
    // Removing should be done in scope of https://supercharged.atlassian.net/browse/FBI-519
    if (import.meta.env.FUZEBOX_GA_ID) {
      window.gtag?.("event", "page_view", {
        ...userAnalyticsProperties,
        page_title: currentPageTitle,
        page_location: location.pathname,
      });
    } else {
      window.dataLayer?.push({
        event: "virtualPageView",
        pageUrl: location.pathname,
        pageTitle: currentPageTitle,
        ...userAnalyticsProperties,
      });
    }
  }, [location, currentPageTitle, userAnalyticsProperties]);

  useEffect(() => {
    window.addEventListener("IubendaConstentGiven", reportPageView);

    return () => {
      window.removeEventListener("IubendaConstentGiven", reportPageView);
    };
  }, [reportPageView]);

  useEffect(() => {
    if (
      currentPageTitle !== DEFAULT_PAGE_TITLE &&
      currentPageTitle !== lastReportedPageTitle.current
    ) {
      reportPageView();
    }
  }, [currentPageTitle, lastReportedPageTitle, reportPageView]);

  return {
    onHelmetChangeClintState,
    defaultPageTitle: DEFAULT_PAGE_TITLE,
  };
};
