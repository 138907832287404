import { DateTime } from "luxon";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useCurrentUser } from "modules/auth/hooks/useCurrentUser";

import {
  fetchLicenceAgreementFile,
  fetchLicenceAgreementLastUpdate,
  selectLicenceAgreement,
} from "../store";

export function useTermsAndConditions() {
  const dispatch = useDispatch();

  const licenceAgreement = useSelector(selectLicenceAgreement);
  const { lastUpdate, file } = licenceAgreement;
  const { currentUser } = useCurrentUser();

  const lastUpdateDate = lastUpdate ? DateTime.fromISO(lastUpdate?.date) : null;

  const userTermsAndConditionsAcceptanceDate = currentUser?.metadata
    ?.termsAcceptanceDate
    ? DateTime.fromISO(currentUser.metadata.termsAcceptanceDate)
    : null;

  const doesUserNeedToAcceptTermsAndConditions =
    userTermsAndConditionsAcceptanceDate && lastUpdateDate
      ? userTermsAndConditionsAcceptanceDate < lastUpdateDate
      : true;

  useEffect(() => {
    if (!lastUpdate) {
      dispatch(fetchLicenceAgreementLastUpdate());
    } else if (!file) {
      dispatch(fetchLicenceAgreementFile({ version: lastUpdate.version }));
    }
  }, [dispatch, lastUpdate, file]);

  return {
    licenceAgreement,
    doesUserNeedToAcceptTermsAndConditions,
  };
}
