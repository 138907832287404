/*eslint perfectionist/sort-objects: "error"*/

export const enGb = {
  accessLevel: "Access Level",
  active: "Active",
  add: "Add",
  addANewUser: "Add a new user",
  addNewUser: "Add new user",
  areYouSureYouWantToDeactivateSca:
    "Are you sure you want to deactivate strong customer authentication for this user? Re-enabling will require user to setup SCA again.",
  areYouSureYouWantToSendResetPasswordEmailToUser:
    "Are you sure you want to send reset password email to {{user}}?",
  backToAllUsers: "Back to all users",
  checkedPermissions: "{{checked}} / {{total}} permissions",
  configuration: "Configuration",
  couldNotRemoveSca:
    "Could not remove strong customer authentication. Please try again later.",
  customer: "Customer",
  customers: "Customers",
  deactivateUser: "Deactivate User",
  edit: "Edit",
  editUser: "Edit user",
  email: "Email",
  "Email address": "Email address",
  emailNotCompliantWithRFC5322:
    "E-mail not compliant with RFC 5322 format. It should contain a valid local part (1-64 characters), '@' symbol, and domain (1-255 characters).",
  fuzeboxAccess: "Fuzebox Access",
  generalInformation: "General information",
  givenUsercouldNotBeFound:
    "Given user could not be found. Make sure you are on correct mode (production/sandbox).",
  inactive: "Inactive",
  isThisAVoltAdminAccount: "Is this a Volt Admin Account?",
  locked: "Locked",
  Name: "Name",
  newUser: "New user",
  pageDescription: `Users who can access the Volt merchant portal from your organisation.
Admin users can add other users, configure applications and make changes to your merchant account.
Standard users can view your transactions and invoices.`,
  Password: "Password",
  "Password confirmation": "Password confirmation",
  permissions: "Permissions",
  Permissions: "Permissions",
  permissionsAmount: "{{count}} permission",
  permissionsAmount_plural: "{{count}} permissions",
  permissionsPreviewSectionDescription:
    "This is the overview of permissions {{name}} has on Fuzebox",
  pleaseOnlySelectForVoltEmployees: "Please only select for Volt employees",
  reactivateUser: "Reactivate User",
  removeSca: "Remove SCA",
  removeStrongCustomerAuthentication: "Remove strong customer authentication",
  resetPassword: "Reset Password",
  roles: "roles",
  saveChanges: "Save changes",
  sca: "SCA",
  scaHasBeenSuccessfullyRemovedForThisUser:
    "Strong customer authentication has been successfully removed for this user.",
  scaIsEnabled: "SCA is enabled",
  scaIsNotEnabled: "SCA is not enabled",
  security: "Security",
  selectCustomerHierarchyPrompt:
    "Select customer hierarchy that the user will be able to access on our platform. Volt user cannot be assigned to a customer.",
  status: "Status",
  strongCustomerAuthentication: "Strong customer authentication",
  theUserHasBeenCreated:
    "The user has been created. An e-mail verification link has been sent to {{email}} to finish the set-up.",
  theUserHasBeenModified: "The user has been modified.",
  thisIsWhereYouCanManageAndAdjustThePermissions:
    "This is where you can manage and adjust the permissions.",
  thisUserIsLockedDueToIncorrectLoginAttempts:
    "This user is locked due to incorrect login attempts",
  twoFaAuthenticatorCannotBeDisabledForVoltAdmins:
    "Two factor authentication cannot be disabled for Volt Admins",
  twoFactorAuthenticator: "Two-factor authentication",
  twoFaHasBeenSuccessfullyRemovedForThisUser:
    "Two-factor authentication has been successfully removed for this user.",
  twoFaIsEnabled: "Two-factor authentication is enabled",
  twoFaIsNotEnabled: "Two-factor authentication is disabled",
  user: "User",
  userDetails: "User details",
  userHasAccessToAllCustomers:
    "The user has access to all Volt customer accounts",
  userHasAccessToCustomersWithinHierarchy:
    "The user has access to all customer accounts within the hierarchy",
  userNotFound: "User not found",
  users: "Users",
  userWontBeAbleToUseFuzebox: "{{user}} won't be able to use Fuzebox.",
  voltAdminUser: "Volt admin user",
  youDontHaveAnyUsersSetUpRightNowDoTalkToYourSystemAdministrator:
    "You don't have any users set up right now, do talk to your system administrator if you think there's an issue or our support team",
  youDontHaveAnyUsersSetUpRightNowLetsGetStarted:
    "You don't have any users set up right now, let's get started",
  youDontHaveEnoughPermissionsToEditThisUser:
    "You don't have enough permissions to edit this user",
};
