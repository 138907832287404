import { useSelector } from "react-redux";

import {
  selectCurrentCustomerId,
  selectCurrentUserId,
  selectCurrentUserIsVoltEmployee,
  selectIsAuthorized,
} from "../store/auth.slice";

export function useCurrentUserAnalyticsProperties() {
  const isAuthorized = useSelector(selectIsAuthorized);
  const userId = useSelector(selectCurrentUserId);
  const isVoltEmployee = useSelector(selectCurrentUserIsVoltEmployee);
  const customerId = useSelector(selectCurrentCustomerId);

  if (!isAuthorized) {
    return {
      // TODO: experiment with Debugging Google Analytics, might be removed or improved
      // Idea is using "anonymous" type to differentiate unauthorized users from data issues
      // https://supercharged.atlassian.net/browse/FBI-483
      type: "anonymous",
      customer: null,
      userId: null,
    };
  }

  const type = isVoltEmployee ? "internal" : "external";
  const customer = customerId ?? "Volt";

  return {
    type,
    customer,
    userId,
  };
}
