import { Skeleton } from "@mui/material";

import type { SxProps, Theme } from "@mui/material";

interface CardDescriptionSkeletonProps {
  height?: number;
  sx?: SxProps<Theme>;
  width?: number;
}

export function CardDescriptionSkeleton({
  height = SKELETON_HEIGHT,
  width = SKELETON_WIDTH,
  sx,
}: CardDescriptionSkeletonProps) {
  return (
    <Skeleton
      sx={{
        width,
        height,
        ...sx,
      }}
    />
  );
}

const SKELETON_WIDTH = 200;
const SKELETON_HEIGHT = 14;
