import { logoutRoute } from "modules/auth/routes/logout";
import { checkoutBrandingRoutes } from "modules/checkout/routes";
import { circuitBreakerRoutes } from "modules/circuit-breaker/routes";
import { connectRoutes } from "modules/connect/routes";
import { settlementsRoutes } from "modules/connect/routes/settlements";
import { dashboardRoutes } from "modules/dashboard/routes";
import { transactionsRoutes } from "modules/payments/routes";
import { payoutsRoutes } from "modules/payouts/routes";
import { refundsRoutes } from "modules/refunds/routes";
import { userSettingsRoutes } from "modules/user-settings/routes";
import { voltAccountsRoutes } from "modules/volt-accounts/routes";
import { workflowsRoutes } from "modules/workflows/routes";

import { configurationRoutes } from "./configuration";

import type { Route } from "./RouteModel";

export const appRoutes: Route[] = [
  workflowsRoutes,
  payoutsRoutes,
  dashboardRoutes,
  transactionsRoutes,
  refundsRoutes,
  userSettingsRoutes,
  logoutRoute,
  configurationRoutes,
  circuitBreakerRoutes,
  connectRoutes,
  ...voltAccountsRoutes,
  ...settlementsRoutes,
  ...checkoutBrandingRoutes,
];
