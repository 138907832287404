export const SIDEBAR_IDS = {
  dashboard: "sidebar_dashboard",
  transactions: "sidebar_transactions",
  transactionsPayments: "sidebar_transactions_payments",
  transactionsRefunds: "sidebar_transactions_refunds",
  transactionsPayouts: "sidebar_transactions_payouts",
  transactionsSettlements: "sidebar_transactions_settlements",
  connect: "sidebar_connect",
  checkoutBranding: "sidebar_checkout_branding",
  circuitBreaker: "sidebar_circuit_breaker",
  circuitBreakerAllowList: "sidebar_circuit_breaker_allow_list",
  circuitBreakerBlockList: "sidebar_circuit_breaker_block_list",
  circuitBreakerRiskRules: "sidebar_circuit_breaker_risk_rules",
  administration: "sidebar_administration",
  administrationWorkflows: "sidebar_administration_workflows",
  configuration: "sidebar_configuration",
  configurationApplications: "sidebar_configuration_applications",
  configurationBankAccount: "sidebar_configuration_bank_account",
  configurationCustomers: "sidebar_configuration_customers",
  configurationUsers: "sidebar_configuration_users",
  voltAccounts: "sidebar_volt_accounts",
  voltAccountsOverview: "sidebar_volt_accounts_overview",
  voltAccountsConnect: "sidebar_volt_accounts_connect",
} as const satisfies Record<string, `sidebar_${string}`>;
