import {
  createAction,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";
import { createMigrate, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { ROLE_2FA_AUTH_REQUIRED } from "../config/roles";
import { rewritePermissionGroupNames } from "./utils";

import type { PayloadAction } from "@reduxjs/toolkit";

import type { Customer } from "modules/customers/models/Customer";
import type { CurrentUser } from "modules/user-management/models/User";
import type { RootState } from "store";
import type { UUID } from "utils";

import type { PERMISSION_GROUP_LIST_ALL } from "../constants/permissionGroups";
import type { LoginCredentials } from "../models/LoginCredentials";
import type {
  AllowedStateRoles,
  Available2FAType,
  JwtPayload,
  LoginApiResult,
  SerializedAuthUser,
} from "./utils";

export interface SliceState {
  accessToken: string | null;
  customer: Customer | null;
  customerId: string | null;
  email: string;
  error: string | null;
  expirationTimestamp: number | null;
  permissions: PERMISSION_GROUP_LIST_ALL[];
  refreshToken: string | null;
  roles: AllowedStateRoles;
  tokenExpiryMs: number | null;
  userId: string | null;
  username: string | null;
  voltEmployee: boolean;
  available_2fa?: Available2FAType;
  currentUser?: Partial<CurrentUser>;
  sca_subject_id?: UUID;
}

export const initialState: SliceState = {
  accessToken: null,
  refreshToken: null,
  expirationTimestamp: null,
  tokenExpiryMs: null,
  userId: null,
  username: null,
  customerId: null,
  customer: null,
  currentUser: {},
  roles: [],
  permissions: [],
  voltEmployee: false,
  email: "",
  error: null,
};

export const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAccessTokenData: (state, action: PayloadAction<SerializedAuthUser>) => {
      Object.assign(state, action.payload);
      state.error = null;
    },
    clearAccessTokenData: (state) => {
      const { email, ...clearState } = initialState;

      Object.assign(state, clearState);
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setAuthCustomer: (state, action: PayloadAction<Customer | null>) => {
      state.customer = action.payload;
    },
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
    setCurrentUserTermsAndConditions: (
      state,
      action: PayloadAction<{ date: string }>
    ) => {
      if (state.currentUser) {
        Object.assign(state.currentUser, {
          metadata: Object.assign(state.currentUser.metadata ?? {}, {
            termsAcceptanceDate: action.payload.date,
          }),
        });
      }
    },
  },
});

export const {
  setAccessTokenData,
  clearAccessTokenData,
  setAuthCustomer,
  setEmail,
  setCurrentUser,
  setCurrentUserTermsAndConditions,
} = slice.actions;

export const authOperationPaths = {
  customer: ["auth", "customer"],
};

export type FetchCurrentUserOptions = { invalidateTag?: boolean } | undefined;

export const login = createAction<LoginCredentials>("login");
export const logout = createAction("logout");
export const forceUpdateToken = createAction("forceUpdateToken");
export const loginSuccess = createAction("loginSuccess");
export const loginFailure = createAction("loginFailure");
export const fetchAuthCustomer = createAction("fetchAuthCustomer");
export const fetchCurrentUser =
  createAction<FetchCurrentUserOptions>("fetchCurrentUser");
export const destroySession = createAction("destroySession");
export const twoFactorCall = createAction<{ code: string }>("twoFactorCall");
export const resendCode = createAction("resendCode");
export const loginSCA = createAction<LoginApiResult>("loginSCA");

export const selectEmail = (state: RootState) => state.auth.email;
export const selectAccessToken = (state: RootState) => {
  const { accessToken, expirationTimestamp } = state.auth;

  if (accessToken && expirationTimestamp) {
    return accessToken && expirationTimestamp > Date.now() ? accessToken : null;
  }

  return null;
};
export const selectIsAuthorized = (state: RootState) => {
  if (isAt2FAStage(state)) {
    return false;
  }

  return Boolean(selectAccessToken(state));
};

export const selectRemainingTokenTimeMs = (state: any) => {
  return state.auth.expirationTimestamp - Date.now();
};
export const selectCurrentUserId = (state: RootState): string | null =>
  state.auth.userId;
export const selectCurrentUsername = (state: RootState) => state.auth.username;
export const selectCurrentCustomerId = (state: RootState) =>
  state.auth.customerId;
export const selectCurrentUser = (state: RootState) => state.auth?.currentUser;
export const selectCurrentUserPermissions = (state: RootState) =>
  state.auth.permissions;
export const selectRefreshToken = (state: RootState): string | null =>
  state.auth.refreshToken;
export const selectCurrentUserIsVoltEmployee = createDraftSafeSelector(
  (state: RootState) => state.auth,
  ({ voltEmployee }): boolean => voltEmployee
);
export const selectCurrentCustomerChildren = (state: RootState) =>
  state.auth.customer?.children;

export const selectCurrentUserCustomer = createDraftSafeSelector(
  (state: RootState) => state.auth,
  ({ customer }) => customer
);
export const selectAuthCustomer = (state: RootState): Customer | null =>
  state.auth.customer;
export const isAt2FAStage = (state: RootState) =>
  (state.auth.roles as string[]).includes(ROLE_2FA_AUTH_REQUIRED);
export const selectAvailable2FA = (state: RootState) =>
  state.auth.available_2fa;
export const selectSCASubjectId = (state: RootState) =>
  state.auth.sca_subject_id;

export const authOperationPath = {
  login: "auth.login",
  twoFactorLogin: "auth.2FA",
  currentUser: "auth.current",
};

export const authPersistedReducer = persistReducer(
  {
    key: "auth",
    storage,
    whitelist: [
      "email",
      "accessToken",
      "refreshToken",
      "expirationTimestamp",
      "userId",
      "username",
      "customerId",
      "permissions",
      "roles",
      "voltEmployee",
      "available_2fa",
      "sca_subject_id",
    ],
    blacklist: [],
    version: 2,
    migrate: createMigrate(
      {
        0: (state: any) => {
          const tokenPayload = jwtDecode<JwtPayload>(state.accessToken);

          return {
            ...state,
            voltEmployee: tokenPayload.volt_employee ?? false,
            permissions: rewritePermissionGroupNames(
              tokenPayload.permissions ?? []
            ),
          };
        },
        1: (state: any) => {
          const tokenPayload = jwtDecode<JwtPayload>(state.accessToken);

          return {
            ...state,
            roles: tokenPayload.roles
              ? tokenPayload.roles.filter(
                  (role) => role === ROLE_2FA_AUTH_REQUIRED
                )
              : [],
            available_2fa: tokenPayload.available_2fa,
          };
        },
        2: (state: any) => {
          const tokenPayload = jwtDecode<JwtPayload>(state.accessToken);

          return {
            ...state,
            username: tokenPayload.username,
          };
        },
      },
      { debug: false }
    ),
  },
  slice.reducer
);
